export const Social_Icons = [
  {
    link: "https://github.com/sarowa36",
    image: "/Github.svg",
    alt: "Github",
  },
  {
    link: "https://www.linkedin.com/in/cemal-mustafao%C4%9Flu-aa75672a0/",
    image: "/LinkedIn.svg",
    alt: "LinkedIn",
  },
];
